import {
  AppFormLabel,
  Badges,
  CardContainer,
  ConfigurationStatusComponent,
  CustomTable,
} from "@/components/common";
import { AddIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  TableContainer,
  Text,
} from "@chakra-ui/react";
import {
  completeInterstial,
  expandDownBlack,
  expandUpIcon,
  rightRotateYellow,
} from "public/assets";

import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import {
  getCoreModelEndpoint,
  getWorkflowGeneric,
} from "@/api-config/api-service";
import { StyleTwoTone } from "@/components/academy/my-learning-profile/course-profile/course-profile-box/icons/StyleTwoTone/StyleTwoTone";
import { getBadgeByState } from "@/components/academy/learning-util-service";
import {
  COMPLETED_STATE_ORG_PHASES,
  CRT_CONFIG_STAGE_MAPPING,
  CRT_ENTITY,
  DASHBOARD_MODEL_KEY,
  ENTITLEMENT_STATUS,
  FIN_ORG_SUB_STEPS,
  GL_CONFIG_STAGE_MAPPING,
  GL_ENTITY,
  IC_CONFIG_STAGE_MAPPING,
  IC_ENTITY,
  ORG_PHASE,
  NO_WORKFLOW_ORG_PHASES,
  SUMMARY_LANDING_PAGE_TYPES,
  SUMMARY_LANDING_SCREEN_STATUS,
  TRANS_CODE_CONFIG_STAGE_MAPPING,
  WORKFLOW_STATUS,
  checkEntitlement,
} from "@/utils";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  IC_LIST_CONFIG,
  GL_LIST_CONFIG,
  ListConfigType,
  CRT_LIST_CONFIG,
} from "./config";

import styles from "./CompletedPage.module.scss";
import { ROUTE_BASE_URL } from "@/route-config/route-path";
import { useRouter } from "next/navigation";
import { productConfigurationContext } from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { setIcCopyFlagInStorage } from "../../ic-utils-service";
import { setGLCopyFlagInStorage } from "@/components/general-ledger/general-ledger-util-service/general-ledger-util-service";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable, getSelectedOrg } from "@/store";

type Props = {
  type: SUMMARY_LANDING_PAGE_TYPES;
  leftBadgeLabel: string;
  rightBadgeLabel: string;
  headingText: string;
  paragraphText: string;
  infoText: string;
  infoSubText: string;
};

const API_MAP = {
  [SUMMARY_LANDING_PAGE_TYPES.ic]: [
    API_ROUTE_CONFIGURATION.accountGroup,
    API_ROUTE_CONFIGURATION.assetCatalogue,
    API_ROUTE_CONFIGURATION.accountType,
    API_ROUTE_CONFIGURATION.FICalendar,
    API_ROUTE_CONFIGURATION.financialOrg,
    API_ROUTE_CONFIGURATION.bankParams,
  ],
  [SUMMARY_LANDING_PAGE_TYPES.gl]: [
    API_ROUTE_CONFIGURATION.accountingSegments,
    API_ROUTE_CONFIGURATION.accounts,
    API_ROUTE_CONFIGURATION.systemAccounts,
    API_ROUTE_CONFIGURATION.sets,
    API_ROUTE_CONFIGURATION.positions,
  ],
  [SUMMARY_LANDING_PAGE_TYPES.crt]: [API_ROUTE_CONFIGURATION.crtConfig],
  [SUMMARY_LANDING_PAGE_TYPES.tc]: [API_ROUTE_CONFIGURATION.tcConfig],
};

const ROUTE_MAP = {
  [SUMMARY_LANDING_PAGE_TYPES.ic]:
    ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM,
  [SUMMARY_LANDING_PAGE_TYPES.gl]: ROUTE_BASE_URL.GENERAL_LEDGER_FORM,
  [SUMMARY_LANDING_PAGE_TYPES.crt]: ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE,
  [SUMMARY_LANDING_PAGE_TYPES.tc]: [API_ROUTE_CONFIGURATION.tcConfig],
};

const STAGE_MAP = {
  [SUMMARY_LANDING_PAGE_TYPES.ic]: IC_CONFIG_STAGE_MAPPING,
  [SUMMARY_LANDING_PAGE_TYPES.gl]: GL_CONFIG_STAGE_MAPPING,
  [SUMMARY_LANDING_PAGE_TYPES.crt]: CRT_CONFIG_STAGE_MAPPING,
  [SUMMARY_LANDING_PAGE_TYPES.tc]: TRANS_CODE_CONFIG_STAGE_MAPPING,
};

const DATA_MAP: Record<string, Record<string, any[]>> = {
  [SUMMARY_LANDING_PAGE_TYPES.ic]: {
    [IC_ENTITY.account_group]: [],
    [IC_ENTITY.asset_catalog]: [],
    [IC_ENTITY.account_type]: [],
    [IC_ENTITY.financial_calendar]: [],
    [IC_ENTITY.financial_organization]: [],
    [IC_ENTITY.bank_parameters]: [],
  },
  [SUMMARY_LANDING_PAGE_TYPES.gl]: {
    [GL_ENTITY.accounting_segments]: [],
    [GL_ENTITY.accounts]: [],
    [GL_ENTITY.system_accounts]: [],
    [GL_ENTITY.sets]: [],
    [GL_ENTITY.positions]: [],
  },
  [SUMMARY_LANDING_PAGE_TYPES.crt]: {
    [CRT_ENTITY.party_relationships]: [],
    [CRT_ENTITY.general_setup]: [],
  },
};
type CustomAccordionProps = {
  item: ListConfigType & {
    entityType: IC_ENTITY | GL_ENTITY | CRT_ENTITY;
    inProgressData: any[];
    completedData: any[];
  };
  entityStageMapping: Record<string, string>;
  type: SUMMARY_LANDING_PAGE_TYPES;
  renderTable: (value: any, key: string, row: any) => React.ReactNode;
  customContent?: (row: any) => React.ReactNode;
  entitlementType: "ic" | "gl";
};

const CustomAccordion = ({
  item: {
    title,
    columns,
    subTitle,
    entityType,
    inProgressData,
    completedData,
    showAddBtn = true,
    disableBtn = false,
  },
  entityStageMapping,
  type,
  renderTable,
  customContent,
  entitlementType,
}: CustomAccordionProps) => {
  const inProgressCount = inProgressData.length;
  const completedCount = completedData.length;
  const router = useRouter();

  const entitlement = useSelector(getEntitlement);
  const isEnvDisabled = useSelector(getEnvIsDisable);

  return (
    <CardContainer customClass={styles["custom-card-container"]}>
      <Accordion allowToggle className={styles["custom-accordion"]}>
        <AccordionItem
          className={styles["custom-accordion-item"]}
          isDisabled={
            entitlement?.[entitlementType] === ENTITLEMENT_STATUS.noAccess
          }
        >
          {({ isExpanded }) => (
            <>
              <Box className={styles["custom-accordion-item-header"]} mb={12}>
                <Box className={styles["custom-accordion-item-left"]}>
                  <Image
                    className={styles["check-img"]}
                    src={completeInterstial}
                    alt="check"
                  />
                  <Box>
                    <Text as="h4" className={styles["accordion-item-title"]}>
                      {title}
                    </Text>
                    <Text as="p" className={styles["accordion-item-subtitle"]}>
                      {subTitle}
                    </Text>
                    <Box className={styles["accordion-item-badge-holder"]}>
                      {completedCount > 0 && (
                        <Badges
                          size="lg"
                          {...getBadgeByState({
                            status: "REQUIRED",
                            showState: true,
                            label: `${completedCount} SET UP`,
                          })}
                        />
                      )}
                      {inProgressCount > 0 &&
                        entityType !== GL_ENTITY.positions && (
                          <Badges
                            size="lg"
                            {...getBadgeByState({
                              status: "STARTED",
                              showState: true,
                              label: `${inProgressCount} IN-PROGRESS`,
                            })}
                          />
                        )}
                    </Box>
                  </Box>
                </Box>
                <AccordionButton className={styles["accordion-item-button"]}>
                  {isExpanded ? (
                    <Image
                      className={styles["accordion-expand"]}
                      src={expandUpIcon}
                      alt="expand up icon"
                    />
                  ) : (
                    <Image
                      className={styles["accordion-expand"]}
                      src={expandDownBlack}
                      alt="expand down icon"
                    />
                  )}
                </AccordionButton>
              </Box>
              {isExpanded && (
                <Divider
                  className={`${styles["content-divider"]} ${styles["orange"]}`}
                />
              )}
              <AccordionPanel>
                {inProgressCount > 0 && entityType !== GL_ENTITY.positions && (
                  <Box mt={12}>
                    <Text as="h6" className={styles["inprogress-heading"]}>
                      JUMP BACK IN
                    </Text>
                    {inProgressData.map((data: any, index: number) => (
                      <Box key={index} className={styles["inprogress-item"]}>
                        <Box className={styles["inprogress-item-left"]}>
                          <Image
                            className={styles["check-img"]}
                            src={rightRotateYellow}
                            alt="check"
                          />
                          <Box>
                            <Text as="h4">{data.name}</Text>
                            <Text as="p">{data.desc}</Text>
                            <Box className={styles["inprogress-item-tag"]}>
                              <Badges
                                size="lg"
                                label={"IN-PROGRESS"}
                                colorScheme="yellow"
                                type="warning"
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className={styles["inprogress-item-right"]}>
                          <Link
                            onClick={() => {
                              if (type === "Institutional Configuration") {
                                setIcCopyFlagInStorage(false);
                              }
                              if (type === "General Ledger Setup") {
                                setGLCopyFlagInStorage(false);
                              }
                            }}
                            href={
                              entitlement?.[entitlementType] ===
                              ENTITLEMENT_STATUS.noAccess
                                ? "#"
                                : data.url
                            }
                          >
                            <Button
                              className={"app-btn-reg-secondary"}
                              rightIcon={
                                <ChevronRightIcon
                                  className={styles["right-icon"]}
                                />
                              }
                              isDisabled={
                                entitlement?.[entitlementType] ===
                                ENTITLEMENT_STATUS.noAccess
                              }
                            >
                              {entitlement[entitlementType] ===
                                ENTITLEMENT_STATUS.readOnly || isEnvDisabled
                                ? "View"
                                : "Resume"}
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                {inProgressCount > 0 && entityType !== GL_ENTITY.positions && (
                  <Divider className={styles["content-divider"]} />
                )}
                <Box className={styles["accordion-table-container"]}>
                  {showAddBtn &&
                    checkEntitlement(entitlement, entitlementType, [
                      ENTITLEMENT_STATUS.allAccess,
                    ]) &&
                    !isEnvDisabled && (
                      <Box className={styles["accordion-table-add-btn"]}>
                        <Button
                          className={"app-btn-inverse-secondary-add-new"}
                          leftIcon={<AddIcon height={4} mr={2} />}
                          onClick={() => {
                            if (type === "Institutional Configuration") {
                              setIcCopyFlagInStorage(false);
                            }
                            if (type === "General Ledger Setup") {
                              setGLCopyFlagInStorage(false);
                            }
                            router.push(
                              `${entityStageMapping[entityType]}?entityId=-1&entityType=${entityType}`
                            );
                          }}
                          isDisabled={disableBtn}
                        >
                          Add new
                        </Button>
                      </Box>
                    )}
                  <TableContainer className={styles["accordion-table-data"]}>
                    <CustomTable
                      data={
                        entityType !== GL_ENTITY.positions
                          ? completedData
                          : [...inProgressData, ...completedData]
                      }
                      columns={columns}
                      renderDataCell={renderTable}
                      className={styles["accordion-table-data"]}
                      isPagination={true}
                      pageSizeData={20}
                      isAccordion={entityType === GL_ENTITY.positions}
                      accordionContent={customContent}
                    />
                  </TableContainer>
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </CardContainer>
  );
};

const CompletedPage = (props: Props) => {
  const { entityWorkflowData } = useContext(productConfigurationContext);
  const [entityListData, setListData] = useState(DATA_MAP[props.type]);
  const [assestClass, setAssetClass] = useState([]);
  const [glCategory, setGLCategory] = useState([]);
  const [accountGroup, setAccountGroup] = useState([]);
  const [accountSeg, setAccountSeg] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const [workflowStatus, setWorkflowStatus] =
    useState<SUMMARY_LANDING_SCREEN_STATUS>(
      props.type !== SUMMARY_LANDING_PAGE_TYPES.crt
        ? SUMMARY_LANDING_SCREEN_STATUS.completed
        : SUMMARY_LANDING_SCREEN_STATUS.not_started
    );
  const router = useRouter();
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const currOrg = useSelector(getSelectedOrg);

  const entitlementType =
    props.type === SUMMARY_LANDING_PAGE_TYPES.ic ? "ic" : "gl";

  const listConfig = useMemo<any>(() => {
    switch (props.type) {
      case SUMMARY_LANDING_PAGE_TYPES.ic:
        return IC_LIST_CONFIG;
      case SUMMARY_LANDING_PAGE_TYPES.gl:
        return GL_LIST_CONFIG;
      case SUMMARY_LANDING_PAGE_TYPES.crt:
        return CRT_LIST_CONFIG;
    }
  }, [props.type]);

  // Generic function to generate inprogress/completed list for different entities
  const _generateData = useCallback(
    (entityType: IC_ENTITY | GL_ENTITY | CRT_ENTITY) => {
      const entityConfig = listConfig[entityType] as ListConfigType;
      const entityApiData = entityListData[entityType];

      // Delimiter for composite keyed entities
      const [mainPk, secPk] = entityConfig.pk.split("~");
      // Delimiter for bank paramaters
      const nameKey = entityConfig.nameKey.split(".");
      const dataWithStatus = entityApiData.reduce<{
        completed: any[];
        inProgress: any[];
      }>(
        (acc, entityData) => {
          // Using ~ delimiter for composite keys
          const identifier = secPk
            ? `${entityData[mainPk]}~${entityData[secPk]}`
            : `${entityData[mainPk]}`; // converting to string
          const workflowForEntity = entityWorkflowData?.find(
            (workflowData) =>
              workflowData.modelKey === identifier &&
              workflowData.stage === entityType
          );
          const url = `${
            ROUTE_MAP[props.type]
          }?entityId=${encodeURIComponent(identifier)}&entityType=${entityType}`; //some entityId is having UTF-8 character so encoding it
          // If nameKey is using a delimiter (Bank params), Get Bank param name
          const name = nameKey[1]
            ? entityListData[IC_ENTITY.financial_organization].find(
                (org: any) => org._Id === entityData._Id
              )?.[nameKey[1]]
            : entityData[nameKey[0]];

          /**
           *  Add entry to completed list when org in prospect phase
           *  OR if org is in production/uat phase and no workflow exists for the entity
           *  OR if workflow is completed
           */
          if (
            currOrg?.phase === ORG_PHASE.prospect ||
            (!workflowForEntity &&
              NO_WORKFLOW_ORG_PHASES.includes(currOrg.phase)) ||
            (entityType === IC_ENTITY.financial_organization
              ? workflowForEntity?.status.includes(
                  FIN_ORG_SUB_STEPS.fin_org_rules
                )
              : workflowForEntity?.status.includes(WORKFLOW_STATUS.completed))
          ) {
            if (
              entityType !== IC_ENTITY.asset_catalog &&
              entityType !== GL_ENTITY.accounts
            ) {
              acc["completed"].push({
                ...entityData,
                url,
                name,
                ...(entityType === GL_ENTITY.positions && {
                  acctGroup: accountGroup[entityData?.acctGroup - 1] ?? "",
                  timezone: timezone?.find(
                    (item: any) => item.tmZoneCode === entityData.tmZoneCode
                  ),
                  acctgSeg: accountSeg
                    .filter((item: any) =>
                      entityData.acctgSeg?.hasOwnProperty(item.key)
                    )
                    .map((item: any) => ({
                      ...item,
                      value: item.value.filter((v: any) =>
                        v.includes(entityData.acctgSeg?.[item.key])
                      ),
                    })),
                  glCat: glCategory[entityData?.glCat - 1] ?? "",
                  assetClass: assestClass[entityData.assetClass - 1] ?? "",
                }),
              });
            } else if (entityType === IC_ENTITY.asset_catalog) {
              //Show Asset class names
              acc["completed"].push({
                ...entityData,
                url,
                name,
                assetClass: assestClass[entityData.assetClass - 1] ?? "",
              });
            } else if (entityType === GL_ENTITY.accounts) {
              acc["completed"].push({
                ...entityData,
                url,
                name,
                acctGroup: accountGroup[entityData?.acctGroup - 1] ?? "",
              });
            }
          } else {
            acc["inProgress"].push({
              ...entityData,
              name,
              url,
              ...(entityType === GL_ENTITY.positions && {
                acctGroup: accountGroup[entityData?.acctGroup - 1] ?? "",
                timezone: timezone?.find(
                  (item: any) => item.tmZoneCode === entityData.tmZoneCode
                ),
                acctgSeg: accountSeg
                  .filter((item: any) =>
                    entityData.acctgSeg?.hasOwnProperty(item.key)
                  )
                  .map((item: any) => ({
                    ...item,
                    value: item.value.filter((v: any) =>
                      v.includes(entityData.acctgSeg?.[item.key])
                    ),
                  })),
                glCat: glCategory[entityData?.glCat - 1] ?? "",
                assetClass: assestClass[entityData.assetClass - 1] ?? "",
              }),
            });
          }
          return acc;
        },
        { completed: [], inProgress: [] }
      );

      return {
        ...entityConfig,
        //For System Accounts in General Ledger Setup if there are two entries in table then disable the "Add New" button
        disableBtn:
          dataWithStatus.completed.length + dataWithStatus.inProgress.length >=
            2 && entityConfig.title === "System Accounts",
        showAddBtn: envIsDisabled ? false : entityConfig.showAddBtn,
        entityType,
        completedData: dataWithStatus.completed,
        inProgressData: dataWithStatus.inProgress,
      };
    },

    [entityWorkflowData, entityListData, props.type, listConfig]
  );

  useEffect(() => {
    (async () => {
      const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
      const res = await Promise.all(
        API_MAP[props.type].map((url) => api.get(url))
      );
      switch (props.type) {
        case SUMMARY_LANDING_PAGE_TYPES.ic:
          //Get assest class data for asset catelog
          const [assetClassData] = await Promise.all([
            api.options(API_ROUTE_CONFIGURATION.assetCatalogue),
          ]).then((data) => data);
          const { assetClass } =
            assetClassData?.actions?.POST?.properties ?? {};

          setListData({
            [IC_ENTITY.account_group]: res[0].data,
            [IC_ENTITY.asset_catalog]: res[1].data,
            [IC_ENTITY.account_type]: res[2].data,
            [IC_ENTITY.financial_calendar]: res[3].data,
            [IC_ENTITY.financial_organization]: res[4].data,
            [IC_ENTITY.bank_parameters]: res[5].data,
          });
          setAssetClass(assetClass?.enumNames ?? []);
          break;
        case SUMMARY_LANDING_PAGE_TYPES.gl:
          const [accountGroupData, timeZoneData, assetClData, glCatData] =
            await Promise.all([
              api.get(API_ROUTE_CONFIGURATION.accountGroup),
              api.get(API_ROUTE_CONFIGURATION.timeZone),
              api.options(API_ROUTE_CONFIGURATION.assetCatalogue),
              api.options(API_ROUTE_CONFIGURATION.positions),
            ]).then((data) => data);
          const { glCat } = glCatData?.actions?.POST?.properties ?? {};
          const assetData = assetClData?.actions?.POST?.properties ?? {};
          const accountGroup =
            accountGroupData?.data?.map(
              (acc: any) => `${acc.acctGroup} - ${acc.desc}`
            ) ?? [];
          const timeZone =
            timeZoneData?.data?.map(({ tmZone, tmZoneCode }: any) => ({
              tmZone,
              tmZoneCode,
            })) ?? [];
          const acctgSeg = res[0].data.map((item: any) => ({
            key: item.acctgSeg,
            value: item.domainVals?.map(
              (val: any) => `${val.acctgSegVal} - ${val.acctgSegDesc}`
            ),
          }));

          setListData({
            [GL_ENTITY.accounting_segments]: res[0].data,
            [GL_ENTITY.accounts]: res[1].data,
            [GL_ENTITY.system_accounts]: res[2].data,
            [GL_ENTITY.sets]: res[3].data,
            [GL_ENTITY.positions]: res[4].data,
          });
          setAccountGroup(accountGroup);
          setTimezone(timeZone);
          setGLCategory(glCat.enumNames);
          setAccountSeg(acctgSeg);
          setAssetClass(assetData?.assetClass?.enumNames ?? []);
          break;
        case SUMMARY_LANDING_PAGE_TYPES.crt:
          setListData({
            [CRT_ENTITY.general_setup]: res[0].data,
            [CRT_ENTITY.party_relationships]: res[1].data,
          });
          break;
      }
    })();
  }, []);

  useEffect(() => {
    if (props.type !== SUMMARY_LANDING_PAGE_TYPES.crt) {
      (async () => {
        const res = await getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.dashboard,
          key: DASHBOARD_MODEL_KEY.foundationalSetup,
        });

        let cPhase = currOrg?.phase ?? "";

        let nextStage = "";
        switch (props.type) {
          case SUMMARY_LANDING_PAGE_TYPES.ic:
            nextStage = "general_ledger";
            break;
          case SUMMARY_LANDING_PAGE_TYPES.gl:
            nextStage = "transaction_codes";
            break;
        }
        const nextStageStatus = res.filter(
          (item: any) => item.stage === nextStage
        );
        setWorkflowStatus(
          nextStageStatus.length > 0
            ? nextStageStatus[0].status
            : COMPLETED_STATE_ORG_PHASES.includes(cPhase)
              ? "completed"
              : "in_progress"
        );
      })();
    }
  }, [props.type, currOrg]);

  const configList = useMemo(() => {
    const allEntitiesData: ReturnType<typeof _generateData>[] = [];
    Object.keys(entityListData).forEach((entityType) => {
      const generatedData = _generateData(entityType as IC_ENTITY | GL_ENTITY);
      allEntitiesData.push(generatedData);
    });
    return allEntitiesData;
  }, [entityListData, _generateData]);

  const renderDataCell = (value: any, key: string, row: any) => {
    switch (key) {
      case "action": {
        return (
          <Box className={styles["more-option-container"]}>
            {entitlement?.[entitlementType] !== ENTITLEMENT_STATUS.noAccess && (
              <Menu>
                <MenuButton
                  className={styles["ellipsis-button"]}
                  as={IconButton}
                  aria-label="Options"
                  icon={<StyleTwoTone className={styles["ellipsis-icon"]} />}
                  variant="outline"
                  color={`var(${"--color-link-hover-color"})`}
                />
                <MenuList className={styles["menu-list-container"]}>
                  <MenuItem
                    className={styles["dropdown-menu-item"]}
                    onClick={() => {
                      if (props.type === "Institutional Configuration") {
                        setIcCopyFlagInStorage(false);
                      }
                      if (props.type === "General Ledger Setup") {
                        setGLCopyFlagInStorage(false);
                      }
                      router.push(row.url);
                    }}
                  >
                    <span className={styles["menu-text"]}>
                      {envIsDisabled ||
                      entitlement[entitlementType] ===
                        ENTITLEMENT_STATUS.readOnly
                        ? "View"
                        : "Edit"}
                    </span>
                  </MenuItem>
                  {!(
                    row.url.includes(GL_ENTITY.system_accounts) ||
                    row.url.includes(IC_ENTITY.bank_parameters) ||
                    envIsDisabled
                  ) &&
                  !envIsDisabled &&
                  entitlement[entitlementType] ===
                    ENTITLEMENT_STATUS.allAccess ? (
                    <MenuItem
                      className={styles["dropdown-menu-item"]}
                      onClick={() => {
                        //entitlement
                        if (props.type === SUMMARY_LANDING_PAGE_TYPES.ic) {
                          setIcCopyFlagInStorage(true);
                          router.push(row.url);
                        } else {
                          //For Financial Institution (Bank) Parameters don't do duplicate as add new is also disabled
                          setIcCopyFlagInStorage(false);
                        }

                        if (props.type === SUMMARY_LANDING_PAGE_TYPES.gl) {
                          setGLCopyFlagInStorage(true);
                          router.push(row.url);
                        } else {
                          setGLCopyFlagInStorage(false);
                          return;
                        }
                      }}
                    >
                      <span className={styles["menu-text"]}>Duplicate</span>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                </MenuList>
              </Menu>
            )}
          </Box>
        );
      }
      default: {
        return value;
      }
    }
  };

  const renderGLPosition = (row: any) => {
    return (
      <HStack className="accordion-content" alignItems={"flex-start"} gap={30}>
        <Box>
          <Text className="inprogress-heading" mb={4}>
            Foundational Setup
          </Text>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Asset class"
              isRequired={true}
              labelFor="assetClass"
            />
            <Text>{row.assetClass}</Text>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Asset identifier"
              isRequired={true}
              labelFor="assetIdentifier"
            />
            <Text>{row.assetId}</Text>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Position balance"
              isRequired={true}
              labelFor="Position balance"
            />
            <Text>{row.bal}</Text>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="Time zone code"
              isRequired={true}
              labelFor="Time zone code"
            />
            <Text>{row?.timezone?.tmZone}</Text>
          </Box>
          <Box className="app-form-field-container">
            <AppFormLabel
              labelName="GL category"
              isRequired={true}
              labelFor="GL category"
            />
            <Text>{row.glCat}</Text>
          </Box>
        </Box>

        <Grid className={styles["glpositionGrid"]}>
          {row.acctgSeg &&
            row.acctgSeg?.slice(0, 10).map((item: any, index: number) => {
              return (
                <GridItem key={index}>
                  <Text className="inprogress-heading" mb={4}>
                    Accounting Segment {index + 1}
                  </Text>
                  <Box className="app-form-field-container">
                    <AppFormLabel
                      labelName={item.key}
                      isRequired={true}
                      labelFor={item.key}
                    />
                    {item?.value?.map((data: string, idx: number) => (
                      <Text key={idx}>{data} </Text>
                    ))}
                  </Box>
                </GridItem>
              );
            })}
        </Grid>
      </HStack>
    );
  };

  return (
    <Box className={styles["ic-completed-container"]}>
      <ConfigurationStatusComponent
        status={SUMMARY_LANDING_SCREEN_STATUS.completed}
        title={props.type}
        leftBadgeLabel={props.leftBadgeLabel}
        leftBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.completed}
        rightBadgeLabel={props.rightBadgeLabel}
        rightBadgeStatus={workflowStatus}
      />
      <Box className={styles["title-container"]} mx="auto">
        <Text as="h3" className={styles["title"]} fontWeight="bold">
          {props.headingText}
        </Text>
        <Text as="p" className={styles["subtitle"]}>
          {props.paragraphText}
        </Text>
      </Box>
      <Box>
        <Divider className={styles["content-divider"]} />
      </Box>
      <Box className={styles["content-container"]}>
        <Text
          as="h4"
          className={styles["content-heading"]}
          fontWeight="semibold"
        >
          {props.infoText}
        </Text>
        <Text as="p" className={styles["content-subtitle"]}>
          {props.infoSubText}
        </Text>
        <Stack gap={12.8} mt={12.8}>
          {configList.map((item) => (
            <CustomAccordion
              key={item.title}
              item={item}
              renderTable={renderDataCell}
              type={props.type}
              entityStageMapping={STAGE_MAP[props.type]}
              customContent={renderGLPosition}
              entitlementType={entitlementType}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default CompletedPage;
